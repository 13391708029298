const dashboard = {
  title: {
    scan_request: "스캔 요청",
    top_url: "가장 많이 요청된 URL",
    map: "국가별 탐지 현황",
    lastest_blog: "최신 블로그",
    detection_rate: "탐지율",
  },
  period: {
    day: "일간",
    week: "주간",
    month: "월간",
    year: "연간",
  },
  time: {
    hour: "시",
    day: "일",
    month: "월",
  },
  chart: {
    requestStatus: "요청 현황",
    totalRequests: "총 요청",
    requests: "건",
  },
  table: {
    rank: "순위",
    url: "URL 주소",
    count: "횟수",
    country: "국가",
    percentage: "점유율",
  },
  weekdays: ["월", "화", "수", "목", "금", "토", "일"],
  no_data: "불러올 데이터가 없습니다.",
};

export default dashboard;
