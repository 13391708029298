import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";
import { useAuthStore } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  ...homeRoutes, // homeRoutes 배열을 병합
  ...loginRoutes, // loginRoutes 배열을 병합
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" }, // 잘못된 경로를 home으로 리디렉션
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, // 라우트를 설정
  scrollBehavior() {
    return { top: 0 }; // 페이지 이동 시 상단으로 스크롤
  },
});

// 전역 네비게이션 가드 (모든 경로에 적용)
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const roleAcl = authStore.role_acl;
  let updatedName = to?.name;

  // `to.name`이 "report" 또는 "contact"이면 "support"으로 변경
  if (updatedName === "report" || updatedName === "contact") {
    updatedName = "support";
  } else if (updatedName === "boannews" || updatedName === "blog") {
    updatedName = "news";
  } else if (updatedName === "nice") {
    updatedName = "nicesupport";
  } else if (updatedName === "blackList") {
    updatedName = "blacklist";
  } else if (updatedName === "whiteList") {
    updatedName = "whitelist";
  } else if (to.path.startsWith("/scanresult/")) {
    updatedName = "scanresult";
  }

  const targetRole = roleAcl.find((role) => role.name === updatedName);

  // 권한 체크
  if (targetRole) {
    const { permission } = targetRole;
    if (permission === 0) {
      return next({ name: "permissiondenied" });
    }
  }

  // 로그인 페이지 접근 시
  if (
    to.name === "login" ||
    to.name === "forgot" ||
    to.name === "reset-password" ||
    to.path.startsWith("/password/reset/")
  ) {
    if (authStore.accessJwt) {
      return next({ name: "status" });
    }
    return next();
  }

  // 비밀번호 관련 페이지가 아닐 경우 토큰 체크
  if (
    to.name !== "forgot" &&
    !to.path.startsWith("/password/reset/") &&
    to.name !== "reset-password"
  ) {
    if (!authStore.accessJwt) {
      return next({ name: "login" });
    }
  }

  // 페이지 이동 시 세션스토리지 초기화
  if (to.path !== from.path && from.path !== "/") {
    sessionStorage.clear();
  }

  try {
    next();
  } catch (error) {
    console.error("토큰 갱신 실패:", error);
    authStore.requestLogout();
    return next({ name: "login" });
  }
});

export default router;
