const search = {
  tab: {
    sms: "SMS 문자",
    qr: "QR코드(이미지)",
  },
  summary: {
    analysis: "분석결과",
    scan_at: "최근 스캔 시간",
    status: "상태",
    tags: "태그",
    issuer: "발급 기관",
    subject: "발급 대상",
    not_before: "발급 날짜",
    not_after: "만료일",
    version: "TLS 버전",
    judgment_basis: "최종 판단 근거(By ‘Generative AI’)",
    analysis_by_engine: "엔진별 분석 결과 및 판단 근거",
    detection: "엔진별 탐지 정보",
    detection_invaild_id: "item ID 정보가 유효하지 않습니다.",
    screenshot: "스크린샷",
    doamin_title: "도메인",
    domain: "도메인 이름",
    registered_date: "등록일",
    registrant: "등록자",
    registrant_address: "등록자 주소",
    updated_date: "최근 갱신일",
    expiration_date: "만료일",
    name_servers: "네임서버",
    ip_address: "IP 주소",
    nation: "국가",
    host_name: "호스트 이름",
    screenshot_none: "스크린샷 이미지가 없습니다.",
    host_ip_none: "호스트IP 정보가 없습니다.",
    domain_none: "도메인 정보가 없습니다.",
    certificate_none: "인증서 정보가 없습니다.",
    detection_none: "탐지 정보가 없습니다.",
    hash_none: "Hash 정보가 없습니다.",
    certificate_title: "인증서",
    show_brief: "간략히 보기",
    show_all: "모두 보기",
  },
  resources: {
    tips: "해당 해시 파일이 포함되어 있는 Domain(URL) 정보를 보여줍니다.",
    resource_info: "리소스 정보",
  },
  scanning: {
    url_scanning: "URL을 스캔하고 있습니다.",
    wait_a_moment: "잠시만 기다려 주세요.",
  },
  audit: {
    title: "감사 로그",
    changes: "변경 사항",
  },
  news: {
    enter_title: "Title을 입력하세요.",
    enter_link: "Link를 입력하세요.",
    enter_published_date: "Published Date를 입력하세요.",
    enter_source: "Source를 입력하세요.",
    enter_lang: "Language를 선택하세요.",
  },
  detection: {
    enter_item_id: "Id를 입력하세요.",
    enter_tags: "Tag를 선택하세요.",
    enter_description: "Description을 입력하세요.",
    enter_domain: "Domain을 입력하세요.",
    enter_url: "URL을 입력하세요.",
    enter_source: "Source을 입력하세요.",
    enter_source_id: "Source ID를 입력하세요.",
    enter_classification: "Classification를 입력하세요.",
    enter_judgment_basis: "판단근거를 입력하세요.",
    enter_target: "Target을 입력하세요.",
  },
  update: {
    before: "갱신 전",
    after: "갱신 후",
    screenshot_dialog_title: "스크린샷 수정",
    screenshot_dialog_no_image: "이미지가 없습니다.",
    screenshot_dialog_select_image: "이미지를 선택해주세요",
    screenshot_dialog_choose_image: "이미지 선택",
    screenshot_dialog_confirm_download: "이미지를 다운로드하시겠습니까?",
    screenshot_dialog_drop_image: "또는 이미지를 여기에 드롭하세요.",
    no_file_selected: "선택된 파일 없음",
  },
  support: {
    process_time: "처리 시간",
    title: "NICE 문의",
    assignee: "담당자",
    assign_to_me: "나에게 할당",
    status: "상태",
    status_wait: "처리 전",
    status_done: "처리완료",
    status_cancel: "취소",
    change_to_cancel: "취소로 변경",
    change_to_wait: "처리 전으로 변경",
    email: "이메일",
    transaction_id: "Transaction ID",
    inquiry_date: "문의 날짜",
    write_answer: "답변 작성",
    cancel: "취소",
    send: "전송",
    answer_time: "답변 시간",
    answer_content: "답변 내용",
    write_comment: "코멘트 작성",
    comment: "코멘트",
    enter_comment: "코멘트를 입력하세요",
    enter_scan_result_url: "스캔 결과 URL 주소를 입력하세요.",
    report_url: "신고 URL",
    contact_url: "문의 URL",
    type: {
      contact: "문의 유형",
      nice: "문의 유형",
      report: "신고 유형",
    },
    contents: {
      contact: "문의 내용",
      nice: "문의 내용",
      report: "신고 내용",
    },
    dialog_title: {
      contact: "문의",
      report: "신고",
      nice: "NICE 지원",
    },
    company_name: "회사명",
    department: "부서명",
    name: "닉네임",
    mobile: "핸드폰번호",
    product_name: "제품명",
    unassigned: "할당되지 않음",
  },
};

export default search;
