const report = {
  top: {
    filter_clear: "Clear",
    placeholder: "Please enter search terms",
    button: {
      all: "All",
      wait: "Wait",
      done: "Done",
      cancel: "Cancel",
    },
  },
  table: {
    header: {
      name: "Title",
      date: "Date",
      email: "Email",
      report_type: "Report Type",
      assignee: "Assignee",
      status: "Status",
    },
    value: {
      report_type: {
        false_negative: "False Negative",
        false_positive: "False Positive",
        etc: "etc",
      },

      status: {
        wait: "Wait",
        done: "Done",
        cancel: "Cancel",
      },
    },
    empty: "No search results found.",
    loading: "Loading data. Please wait a moment.",
    current_page_report: "Showing {first} to {last} of {totalRecords}",
  },
};

export default report;
