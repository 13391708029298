const dashboard = {
  title: {
    scan_request: "Scan Requests",
    top_url: "Most Requested URLs",
    map: "Country Scan Status ",
    lastest_blog: "Latest Blog",
    detection_rate: "Detection rate",
  },
  period: {
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
    year: "Yearly",
  },
  time: {
    hour: "h",
    day: "d",
    month: "m",
  },
  chart: {
    requestStatus: "Request Status",
    totalRequests: "Total Requests",
    requests: "requests",
  },
  table: {
    rank: "Rank",
    url: "URL Address",
    count: "count",
    country: "Country",
    percentage: "Percentage",
  },
  weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  no_data: "No data available.",
};

export default dashboard;
