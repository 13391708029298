const search = {
  tab: {
    sms: "SMS Messages",
    qr: "QR Code(Image)",
  },
  summary: {
    analysis: "Analysis",
    scan_at: "Last scan date",
    status: "Status",
    tags: "Tags",
    issuer: "Issuer",
    subject: "Subject",
    not_before: "Date of issue",
    not_after: "Expiration date",
    version: "TLS Version",
    judgment_basis: "Basis for final judgment(By ‘Generative AI’)",
    analysis_by_engine: "Analysis results and judgment basis for each engine",
    detection: "Detection Information by Engine",
    detection_invaild_id: "The item ID information is invalid.",
    screenshot: "Screenshot",
    doamin_title: "Domain",
    domain: "Domain Name",
    registered_date: "Registration date",
    registrant: "Registrant",
    registrant_address: "Registrant address",
    updated_date: "Updated date",
    expiration_date: "Expiration date",
    name_servers: "Name server",
    ip_address: "IP address",
    nation: "Nation",
    host_name: "Host name",
    screenshot_none: "There are no screenshot images.",
    host_ip_none: "Host IP information does not exist.",
    domain_none: "Domain information does not exist.",
    certificate_none: "Certificate information does not exist.",
    detection_none: "Detection information does not exist.",
    hash_none: "Hash information does not exist.",
    certificate_title: "Certificate",
    show_brief: "Show Brief",
    show_all: "Show All",
  },
  resources: {
    tips: "It shows the Domain (URL) information containing the hash file.",
    resource_info: "Resource Information",
  },
  scanning: {
    url_scanning: "Scanning URL.",
    wait_a_moment: "please wait for a moment.",
  },
  audit: {
    title: "Audit Log",
    changes: "Changes",
  },
  news: {
    enter_title: "Please enter title",
    enter_link: "Please enter link",
    enter_published_date: "Please enter published date",
    enter_source: "Please enter source",
    enter_lang: "Please select language",
  },
  detection: {
    enter_item_id: "Please enter the ID.",
    enter_tags: "Please select tags.",
    enter_description: "Please enter the description.",
    enter_domain: "Please enter the domain.",
    enter_url: "Please enter the URL.",
    enter_source: "Please enter the source.",
    enter_source_id: "Please enter the source ID.",
    enter_classification: "Please enter the classification.",
    enter_judgment_basis: "Please enter the judgment basis.",
    enter_target: "Please enter the target.",
  },
  update: {
    before: "Before Update",
    after: "After Update",
    screenshot_dialog_title: "Edit Screenshot",
    screenshot_dialog_no_image: "No image available",
    screenshot_dialog_select_image: "Please select an image",
    screenshot_dialog_choose_image: "Select Image",
    screenshot_dialog_confirm_download: "Would you like to download the image?",
    screenshot_dialog_drop_image: "Or drop the image here.",
    no_file_selected: "No file selected",
  },
  support: {
    process_time: "Processing Time",
    title: "NICE Inquiry",
    assignee: "Assignee",
    assign_to_me: "Assign to Me",
    status: "Status",
    status_wait: "Waiting",
    status_done: "Completed",
    status_cancel: "Cancelled",
    change_to_cancel: "Change to Cancel",
    change_to_wait: "Change to Waiting",
    email: "Email",
    inquiry_type: "Inquiry Type",
    transaction_id: "Transaction ID",
    inquiry_date: "Inquiry Date",
    inquiry_content: "Inquiry Content",
    write_answer: "Write Answer",
    cancel: "Cancel",
    send: "Send",
    answer_time: "Answer Time",
    answer_content: "Answer Content",
    write_comment: "Write Comment",
    comment: "Comment",
    enter_comment: "Enter comment",
    enter_scan_result_url: "Enter the scan result URL.",
    report_url: "Report URL",
    contact_url: "Contact URL",
    type: {
      contact: "Inquiry Type",
      nice: "Inquiry Type",
      report: "Report Type",
    },
    contents: {
      contact: "Inquiry Content",
      nice: "Inquiry Content",
      report: "Report Content",
    },
    dialog_title: {
      contact: "Inquiry",
      report: "Report",
      nice: "NICE Support",
    },
    company_name: "Company",
    department: "Department",
    name: "Name",
    mobile: "Mobile",
    product_name: "Product",
    unassigned: "Unassigned",
  },
};

export default search;
