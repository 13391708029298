const scanResult = {
  top: {
    filter_clear: "初期化",
    placeholder: "検索語を入力してください",
  },
  table: {
    header: {
      name: "名前",
      date: "日付",
      domain: "ドメイン",
      title: "タイトル",
      tag: "タグ",
      detection_info: "検出情報",
      resource_num: "リソース数",
      link_num: "リンク数",
      country: "国",
      published_date: "投稿日",
      language: "言語",
      elapsed: "経過時間",
    },
    empty: "検索結果がありません。",
    loading: "データを読み込んでいます。しばらくお待ちください。",
    current_page_report: "全体{totalRecords}件中{first}~{last}番目のデータ出力",
  },
};

export default scanResult;
