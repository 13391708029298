const urlRequestRoutes = [
  {
    path: "/scanrequest/url",
    name: "urlscanresult",
    component: () =>
      import(
        /* webpackChunckName: "urlrequest" */ "@/views/scanRequest/UrlScanRequestView.vue"
      ),
  },
];

export default urlRequestRoutes;
