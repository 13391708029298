const contact = {
  top: {
    filter_clear: "Clear",
    placeholder: "Enter search term",
    button: {
      all: "All",
      wait: "Wait",
      done: "Done",
      cancel: "Cancel",
    },
  },
  table: {
    header: {
      date: "Date",
      company_name: "Company / Individual",
      name: "Nickname (Name)",
      product: "Product",
      mobile: "Mobile",
      email: "Email",
      assignee: "Assignee",
      status: "Status",
    },
    value: {
      status: {
        wait: "Wait",
        done: "Done",
        cancel: "Cancel",
      },
    },
    empty: "No search results found.",
    loading: "Loading data. Please wait a moment.",
    current_page_report: "Showing {first} to {last} of {totalRecords}",
  },
};

export default contact;
