import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { useMainStore } from "@/store/main";
import enUS from "./locales/en-US";
import koKR from "./locales/ko-KR";
import jaJP from "./locales/ja-JP";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import primeVue from "primevue/config";
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import "primeicons/primeicons.css";
import "quill/dist/quill.snow.css";
import lara from "@primevue/themes/lara";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { definePreset } from "@primevue/themes";
import "./assets/styles/tailwind.css";
const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(ToastService)
  .use(ConfirmationService)
  .use(createPinia());

const mainStore = useMainStore();
type MessageSchema = typeof koKR;
const i18n = createI18n<[MessageSchema], "en-US" | "ko-KR" | "ja-JP">({
  legacy: false,
  locale: mainStore.language,
  messages: {
    "en-US": enUS,
    "ko-KR": koKR,
    "ja-JP": jaJP,
  },
});

const AucPreset = definePreset(lara, {
  semantic: {
    primary: {
      50: "{red.50}",
      100: "{red.100}",
      200: "{red.200}",
      300: "{red.300}",
      400: "{red.400}",
      500: "{red.500}",
      600: "{red.600}",
      700: "{red.700}",
      800: "{red.800}",
      900: "{red.900}",
      950: "{red.950}",
    },
  },
});

app.use(primeVue, {
  theme: {
    preset: AucPreset,
    options: {
      darkModeSelector: ".app-dark",
    },
  },
});

app.use(ToastService);
app.directive("ripple", Ripple);
app.directive("tooltip", Tooltip);
app.use(i18n);

app.mount("#app");
