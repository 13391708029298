const contact = {
  top: {
    filter_clear: "クリア",
    placeholder: "検索キーワードを入力してください",
    button: {
      all: "すべて",
      wait: "未処理",
      done: "処理済み",
      cancel: "キャンセル",
    },
  },
  table: {
    header: {
      date: "日付",
      company_name: "会社名 / 個人",
      name: "ニックネーム（名前）",
      product: "製品名",
      mobile: "携帯電話",
      email: "メール",
      assignee: "担当者",
      status: "状態",
    },
    value: {
      status: {
        wait: "未処理",
        done: "処理完了",
        cancel: "キャンセル",
      },
    },
    empty: "検索結果がありません。",
    loading: "データを読み込んでいます。しばらくお待ちください。",
    current_page_report: "全 {totalRecords} 件中 {first} ～ {last} 件を表示",
  },
};

export default contact;
