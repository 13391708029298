const report = {
  top: {
    filter_clear: "초기화",
    placeholder: "검색어를 입력하세요",
    button: {
      all: "전체",
      wait: "처리 전",
      done: "처리 완료",
      cancel: "취소",
    },
  },
  table: {
    header: {
      name: "제목",
      date: "날짜",
      email: "이메일",
      report_type: "신고 유형",
      assignee: "담당자",
      status: "상태",
    },
    value: {
      report_type: {
        false_negative: "미탐",
        false_positive: "오탐",
        etc: "기타",
      },
      status: {
        wait: "처리 전",
        done: "처리 완료",
        cancel: "취소",
      },
    },
    empty: "검색 결과가 없습니다.",
    loading: "데이터를 불러오고 있습니다. 잠시만 기다려주세요.",
    current_page_report:
      "전체 {totalRecords}개 중 {first} ~ {last}번째 데이터 출력",
  },
};

export default report;
