const scanResultsRoutes = [
  {
    path: "/scanresult/url",
    name: "scanresult-url",
    component: () =>
      import(
        /* webpackChunckName: "scanresult" */ "@/views/scanResult/UrlScanResultsView.vue"
      ),
  },
  // {
  //   path: "/scanresult/sms",
  //   name: "scanresult-sms",
  //   component: () =>
  //     import(
  //       /* webpackChunckName: "scanresult" */ "@/views/scanResult/SmsScanResultsView.vue"
  //     ),
  // },
  {
    path: "/scanresult/:id",
    name: "scanresult-summary",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "/scanresult/:id/resources",
    name: "scanresult-resources",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "/scanresult/:id/links",
    name: "scanresult-links",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
];

export default scanResultsRoutes;
