import alert from "./alert";
import login from "./login";
import common from "./common";
import scanResult from "./scanResult";
import menu from "./menu";
import search from "./search";
import report from "./report";
import contact from "./contact";
import dashboard from "./dashboard";

const enUS = {
  alert,
  common,
  login,
  scanResult,
  menu,
  search,
  report,
  contact,
  dashboard,
};

export default enUS;
