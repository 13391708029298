const permissionRoutes = [
  {
    path: "/permissiondenied",
    name: "permissiondenied",
    component: () =>
      import(
        /* webpackChunckName: "report" */ "@/views/audit/permissionView.vue"
      ),
  },
];

export default permissionRoutes;
