const menu = {
  dashboard: "대시보드",
  scanResult: "스캔 결과",
  management: "관리",
  account: "계정",
  support: "지원",
  status: "현황",
  dashboard2: "대시보드 2",
  whiteList: "화이트 리스트",
  blackList: "블랙 리스트",
  permutation: "퍼뮤테이션",
  pageTitle: "페이지 제목",
  pattern: "패턴",
  report: "신고",
  contact: "문의",
  mypage: "마이페이지",
  logout: "로그아웃",
  audit: "감사 로그",
  securityNews: "보안 소식",
  news: "뉴스",
  blog: "블로그",
  nice: "NICE 지원",
  scanrequest: "스캔 요청",
  smsscan: "SMS",
  urlscan: "URL",
};

export default menu;
