const dashboard = {
  title: {
    scan_request: "スキャンリクエスト",
    top_url: "最もリクエストされたURL",
    map: "国別データ",
    lastest_blog: "最新のブログ",
    detection_rate: "検出率",
  },
  period: {
    day: "日次",
    week: "週次",
    month: "月次",
    year: "年次",
  },
  time: {
    hour: "時",
    day: "日",
    month: "月",
  },
  chart: {
    requestStatus: "リクエスト状況",
    totalRequests: "総リクエスト",
    requests: "件",
  },
  table: {
    rank: "順位",
    url: "URLアドレス",
    count: "回",
    country: "国",
    percentage: "占有率",
  },
  weekdays: ["月", "火", "水", "木", "金", "土", "日"],
  no_data: "データがありません",
};

export default dashboard;
