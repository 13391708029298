const report = {
  top: {
    filter_clear: "クリア",
    placeholder: "検索語を入力してください",
    button: {
      all: "全て",
      wait: "処理前",
      done: "処理後",
      cancel: "キャンセル",
    },
  },
  table: {
    header: {
      name: "タイトル",
      date: "日付",
      email: "メール",
      report_type: "報告の種類",
      assignee: "担当者",
      status: "状態",
    },
    value: {
      report_type: {
        false_negative: "未検出",
        false_positive: "誤検出",
        etc: "その他",
      },
      status: {
        wait: "待機",
        done: "完了",
        cancel: "キャンセル",
      },
    },
    empty: "検索結果がありません。",
    loading: "データを読み込んでいます。しばらくお待ちください。",
    current_page_report: "全 {totalRecords} 件中 {first} ~ {last} 件を表示",
  },
};

export default report;
