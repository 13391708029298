import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "@/store/auth";
import newsRoutes from "./newsRoutes";
import managementRoutes from "./managementRoutes";
import supportRoutes from "./supportRoutes";
import scanResultsRoutes from "./scanRoutes";
import auditRoutes from "./auditRoutes";
import niceRoutes from "./niceRoutes";
import permissionRoutes from "./permissionRoutes";
import urlRequestRoutes from "./urlRequestRoutes";
const homeRoutes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();

      // 로그인 직후 라우팅인 경우 토큰 갱신 스킵
      if (from.name === "login" && authStore.accessJwt) {
        return next();
      }

      return next(); // 로그인 상태라면 계속 진행
    },
    children: [
      {
        path: "",
        name: "default",
        redirect: "/dashboard/status",
      },
      {
        path: "dashboard/status",
        name: "status",
        component: () =>
          import(
            /* webpackChunkName: "status" */ "@/views/dashboard/StatusView.vue"
          ),
      },
      {
        path: "dashboard/dashboard2",
        name: "dashboard2",
        component: () =>
          import(
            /* webpackChunkName: "dashboard2" */ "@/views/dashboard/DashboardView2.vue"
          ),
      },
      ...newsRoutes,
      ...scanResultsRoutes,
      ...urlRequestRoutes,
      ...managementRoutes,
      ...supportRoutes,
      ...auditRoutes,
      ...niceRoutes,
      ...permissionRoutes,
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/dashboard/status",
  },
];

export default homeRoutes;
