const common = {
  ok: "確認",
  cancel: "キャンセル",
  apply: "適用",
  view: "表示",
  edit: "編集",
  add: "追加",
  copy: "コピーする",
  detail: "詳細を見る",
};

export default common;
